import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type Property={
  article?: boolean;
  title?: string;
  image?: string;
  description?: string;
}

export const Seo: FunctionComponent<Property> = ({
  article=false,
  title,
  image="/images/home_small.png",
  description
}: Property) =>{
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            home
          }
        }
      }
    `
  );

  const siteMetadata = data.site.siteMetadata;

  const metaDescription = description || siteMetadata.description;
  const metaImage = `${siteMetadata.home}${image}`;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'ja',
        prefix: "og: http://ogp.me/ns#",
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
        property: `og:image`,
        content: metaImage,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: article ? `article` : `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat([])}
    />
  )
}
