import React, { FunctionComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

export const HeaderImage: FunctionComponent = () =>{
  const data = useStaticQuery(
    graphql`
    query HeaderImage{
        imageFile: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home.png"}) {
          childImageSharp {
            fixed(height: 42) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  return (
    <Img fixed={data.imageFile.childImageSharp.fixed} objectFit="contain" />
  );
}