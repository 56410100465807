import React, { FunctionComponent } from "react";
import { AppBar, Container, Box, Grid } from "@material-ui/core";
import styled from "styled-components";
import { HomeLinkButton } from "./HomeLinkButton";
import { HeaderLinkItem } from "./HeaderLinkItem";

const StyledHeaderBar = styled(AppBar)`
  background-color: #fff;

  border-bottom-style: solid;
  border-bottom-color: ${prop => prop.theme.palette.primary.light};
`;

export const Header: FunctionComponent = () =>(
  <StyledHeaderBar color="inherit" position="sticky" >
    <Container>
      <Grid container direction="row" alignItems="flex-end" spacing={1}>
        <Grid item>
          <Box mr={4}>
            <HomeLinkButton />
          </Box>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="flex-end" spacing={1}>
            <Grid item>
              <HeaderLinkItem label="contents" link="/contents" />
            </Grid>
            <Grid item>
              <HeaderLinkItem label="posts" link="/posts" />
            </Grid>
            <Grid item>
              <HeaderLinkItem label="knowledges" link="/knowledges" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </StyledHeaderBar>
);