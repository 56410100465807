import React, { FunctionComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled, { ThemeConsumer } from "styled-components";
import { Box, Typography, Link, Grid } from "@material-ui/core";

const StyledBox = styled(Box)`
  background-color: ${prop => prop.theme.palette.secondary.main};
  color: ${prop => prop.theme.palette.secondary.contrastText};
`;

export const Footer: FunctionComponent = () =>{
  const data = useStaticQuery(
    graphql`
      query SiteMetadataForFooter{
        site {
          siteMetadata {
            author
            since
          }
        }
      }
    `
  );

  const since: number = data.site.siteMetadata.since;
  const author: string = data.site.siteMetadata.author;
  const nowYear: number = new Date().getFullYear();
  const yearString: string = `${since}-${nowYear}`;

  return(
    <StyledBox component="footer" py={2}>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Typography variant="body2">
            © {yearString} {author}, built with <Link href="https://www.gatsbyjs.org/" color="inherit" underline="always">Gatsby</Link>
          </Typography>
        </Grid>
      </Grid>
    </StyledBox>
  );
}