import React, { FunctionComponent, Fragment } from "react";
import { ThemeProvider as MaterialThemeProvider, CssBaseline, Box, Container } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import styled, { ThemeProvider as StyledThemeProvider } from "styled-components";
import { Header } from "../components/headers/Header";
import { Footer } from "../components/footers/Footer";
import { AppColor } from "../styles/AppColor";

const theme = createMuiTheme({
  palette: {
    primary: {
      dark: '#F57C00',
      main: '#FFB01D',
      light: '#FFDB4F',
      contrastText: '#fff',
    },
    secondary: {
      main: '#719108',
      light: '#B0D80C',
      contrastText: '#fff',
    }
  },
  overrides: {
    MuiTypography:{
      body1: {
        lineHeight: "1.7em",
      },
      h1: {
        fontWeight: 400,
        fontSize: "2.25rem",
        lineHeight: 1.1,
      }
    }
  }
});

const StyledBackground = styled(Box)<{iswhite: number}>`
  background-color: ${prop => prop.iswhite ? "#fff" : AppColor.background};

  min-height: 100vh;
`;

type Property = {
  white?: boolean;
}

export const Layout: FunctionComponent<Property> = ({white=false, children}) =>(
  <MaterialThemeProvider theme={theme}>
    <StyledThemeProvider theme={theme}>
      <CssBaseline />
      <StyledBackground iswhite={white ? 1 : 0}>
        <Header />
          <Container>
            <Box component="main" my={4}>
              {children}
            </Box>
          </Container>
        <Footer />
      </StyledBackground>
    </StyledThemeProvider>
  </MaterialThemeProvider>
)