import React, { FunctionComponent } from "react";
import { Button, Typography, Box } from "@material-ui/core";
import styled from "styled-components";
import { AppColor } from "../../styles/AppColor";

type Property = {
  label: string;
  link: string;
}
const StyledButton = styled(Button)`
  border-radius: 0;

  padding: 0px;
`;

const StyledText = styled(Typography)`
  color: ${AppColor.headerLinkTextColor};
  font-weight: 600;
  font-size: 1rem;
  text-transform: none;
`;

export const HeaderLinkItem: FunctionComponent<Property> = (props) =>(
  <StyledButton href={props.link} color="primary">
    <StyledText variant="button">
      <Box px={2}>
        {props.label}
      </Box>
    </StyledText>
  </StyledButton>
)