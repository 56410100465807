import React, { FunctionComponent } from "react";
import { Button } from "@material-ui/core";
import { HeaderImage } from "./HeaderImage";
import styled from "styled-components";

const StyledButton = styled(Button)`
  border-radius: 0;
`;

export const HomeLinkButton: FunctionComponent = () =>{
  return (
    <StyledButton color="primary" href="/">
      <HeaderImage />
    </StyledButton>
  );
}